export default function (id) {
    let window = 'localidade.remocao.new'
    !this.$uloc.window.get(window) && this.$uloc.window.new({
        wid: window,
        title: id ? `Localidade #${id}` : 'Nova Localidade',
        width: '80%',
        height: '70%',
        minHeight: '500px',
        maxWidth: '900',
        backdrop: true,
        clickOutside: false,
        windowClass: 'erp-window',
        contentClass: 'overflow-hidden',
        props: {
            id
        }
    }, () => import('../components/origensDestinos/window/LocalidadeRemocao'))
        .then((wid) => {
            this.$uloc.window.listen(wid, {
                update: (wid, val) => {
                    console.log('Atualizado: ', val)
                    this.load?.()
                }
            })
        }) // return wid
}
