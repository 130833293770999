<script>
import Layout from '../../layout/Layout'
import Menu from '../menu'
import {
  listaLocais as list,
  deleteLocalRemocao
} from '@/domain/remocao/services'
import {UTable, UTh, UTr, UTd, UCheckbox, date} from 'uloc-vue'
import {
  EBtnTableOptions
} from 'uloc-vue-plugin-erp'
import MenuOptions from '../../layout/context-menu/context-window-options'
import SearchFilter from '../../layout/helpers/SearchFilter'
import {ErpInput, /*ErpSelect,*/ ErpSField} from 'uloc-vue-plugin-erp'
import DefaultMixin from '../mixin'
import {datePtToEn} from '@/utils/date'
import tableColumnsCache from "@/utils/tableColumnsCache"
import DefaultListMenu from "@/components/remocao/components/context/locais-list-menu"
import listOptions from "@/components/leiloes/windows/listOptions";
import ERow from "@/components/layout/components/Row";
import ECol from "@/components/layout/components/Col";
import windowLocalidadeRemocao from '../windows/localidade-remocao'

const listStorage = tableColumnsCache('localRemocao.list', [
  {label: 'ID', name: 'id', active: true, sortable: true, ordering: 1},
  {label: 'Nome', name: 'nome', active: true, sortable: true, ordering: 1},
  {label: 'UF', name: 'uf', active: true, sortable: true, ordering: 1},
  {label: 'Cidade', name: 'cidade', active: true, sortable: true, ordering: 1}
], 1)

let filters = {
  id: null,
  nome: null,
  cidadeOld: null,
  ufOld: null,
}

export default {
  name: 'LocaisRemocao',
  mixins: [DefaultMixin],
  components: {
    ECol,
    ERow,
    DefaultListMenu,
    // DefaultAsyncSelect,
    // DateInput,
    SearchFilter,
    MenuOptions,
    Layout,
    UTable,
    UTh,
    UTr,
    UTd,
    UCheckbox,
    EBtnTableOptions,
    ErpInput,
    // ErpSelect,
    ErpSField
  },
  data() {
    return {
      listStorage: listStorage,
      listType: 'ativos',
      table: {
        busca: '',
        serverData: [],
        serverPagination: {
          page: 1,
          rowsNumber: 10, // specifying this determines pagination is server-side
          rowsPerPage: 20
        },
        columns: listStorage.map(o => {
          return {
            ...o,
            field: o.name,
            align: 'left'
          }
        }).concat([
          {
            name: 'options',
            required: true,
            label: '',
            field: 'options',
            sortable: false
          }
        ]),
        filter: '',
        filters: JSON.parse(JSON.stringify(filters)),
        selected: [],
        loading: false
      }
    }
  },
  computed: {
    menu() {
      return Menu
    },
    menuDashOptions() {
      return MenuOptions
    },
    visibledColumns() {
      return this.listStorage.filter(item => item.active).map(item => item.name)
    }
  },
  mounted() {
    this.load()
  },
  destroyed() {
    // document.querySelector('.u-erp-layout-container').classList.remove('bg-white')
  },
  methods: {
    request({pagination, filter, format}) {

      let data1, data2
      let extraFilters = []

      if (this.table.filters.data1) {
        if (this.table.filters.data1.length < 10 || this.table.filters.data2.length < 10) {
          alert('Digite a data inicial e data final corretamente para o filtro de entrada.')
          return
        }

        data1 = datePtToEn(this.table.filters.data1)
        data2 = datePtToEn(this.table.filters.data2)
        if (!date.isValid(data1) || !date.isValid(data2)) {
          alert('Digite a data inicial e data final corretamente. Formato: dd/mm/yyyy')
          return
        }
        extraFilters.push(`&data1=${data1}&data2=${data2}`)
      }

      this.table.filters.id && extraFilters.push(`&id=${this.table.filters.id}`)
      this.table.filters.codigo && extraFilters.push(`&codigo=${this.table.filters.codigo}`)
      this.table.filters.tipo && extraFilters.push(`&tipo=${this.table.filters.tipo}`)
      this.table.filters.classificacao && extraFilters.push(`&classificacao=${this.table.filters.classificacao}`)
      this.table.filters.leiloeiro && extraFilters.push(`&leiloeiro=${this.table.filters.leiloeiro}`)

      if (Array.isArray(this.table.filters.status) && this.table.filters.status.length > 0) {
        extraFilters.push(`&status=${this.table.filters.status.join(',')}`)
      }

      if (Array.isArray(this.table.filters.statusInterno) && this.table.filters.statusInterno.length > 0) {
        extraFilters.push(`&statusInterno=${this.table.filters.statusInterno.join(',')}`)
      }

      if (format === 'excel' || format === 'xlsx') {
        extraFilters.push('&format=excel')
      } else if(format === 'html') {
        extraFilters.push('&format=html')
      }

      this.table.loading = true
      let _filter = this.filtros
      let filtros = encodeURI(`&sortBy=${pagination.sortBy || 'dataProximoLeilao'}&descending=${pagination.sortBy ? (pagination.descending ? 'true' : 'false') : (this.listType === 'ativos' ? 'false' : true)}&search=${filter}${extraFilters.join('')}`)


      list(pagination.rowsPerPage, pagination.page, filtros, format)
          .then((response) => {
            console.log(response.data)
            let data = response.data
            this.loading = false
            this.table.serverPagination = pagination
            this.table.serverPagination.rowsNumber = data.total

            this.table.serverData = data.result

            this.table.loading = false
          })
          .catch(error => {
            this.alertApiError(error)
            this.table.loading = false
            this.loading = false
          })
    },
    load(format = null) {
      this.request({
        pagination: this.table.serverPagination,
        filter: this.table.busca,
        format: format
      })
    },
    clearFilters() {
      this.table.filters = JSON.parse(JSON.stringify(filters))
    },
    novo() {
      console.log('New...')
      this.windowLocalidadeRemocao()
    },
    pesquisar() {
      this.request({
        pagination: this.table.serverPagination,
        filter: this.table.filters.descricao || this.table.busca
      })
    },
    abrir(id) {
      this.windowLocalidadeRemocao(id)
    },
    edit(id) {
      this.windowLocalidadeRemocao(id)
    },
    excluir(id) {
      this.confirmarExclusao('Tem certeza que deseja excluir esta localidade?', 'excluir localidade-' + id)
          .then(() => {
            this.$uloc.loading.show()
            this.deleteLocalidade(id)
          })
          .catch(() => {
          })
    },
    deleteLocalidade (id) {
      deleteLocalRemocao(id)
      .then(response => {
        this.$uloc.notify({
          type: 'positive',
          message: 'Localidade removida com sucesso',
          icon: 'check'
        })
        this.load()
      })
      .catch(error => {
        console.log(error)
        this.alertApiError(error)
      })
      .finally(() => {
        this.$uloc.loading.hide()
      })
    },
    listOptions: listOptions,
    updateListDefinition(newConfig) {
      LocalStorage.set('localRemocao.list', newConfig)
      this.listStorage = newConfig
    },
    changeListType (v) {
      this.listType = v
      if (v === 'ativos') {
        this.table.filters.status = [0, 1, 2, 3, 4]
      } else {
        this.table.filters.status = [96, 97, 98, 99]
      }
      this.load()
    },
    imprimir () {
      this.load('html')
    },
    importar () {
      console.log('Import...')
      this.$router.push({name: 'remocao.locais.importarTabelaPrecos'})
    },
    windowLocalidadeRemocao
  },
  meta: {
    title: 'Locais de Remoção',
    name: 'Locais de Remoção'
  }
}
</script>

<template>
  <layout class="erp-list" :menu="menu" menu-style="compact" menu-active="origens-destinos">
    <div>
      <search-filter>
        <form id="leilaoFilter" class="m-t-sm">
          <e-row class="row erp-row def-m-r">

            <e-col style="max-width: 110px; min-width: 60px">
              <erp-s-field
                  view="tl"
                  label="ID"
              >
                <erp-input shortkey="F3" v-model="table.filters.id" @keydown.enter="pesquisar"/>
              </erp-s-field>
            </e-col>

            <e-col>
              <erp-s-field
                  view="tl"
                  label="Descrição"
              >
                <erp-input shortkey="F4" v-model="table.filters.descricao" @keydown.enter="pesquisar"/>
              </erp-s-field>
            </e-col>

          </e-row>

          <e-row class="row erp-row def-m-r">

            <e-col class="flex justify-end m-t-sm def-list-buttons" style="min-width: 300px">
              <div class="flex flex-center column m-r-xs">
                <u-btn class="pesquisar" label="Pesquisar" v-shortkey="['shift', 'enter']" @shortkey.native="pesquisar"
                       @click="pesquisar"/>
                <span class="shortcut">Shift + Enter</span>
              </div>
              <div class="flex flex-center column m-r-xs">
                <u-btn @click="clearFilters" class="limpar" label="Limpar Filtros" v-shortkey="['ctrl', 'shift', 'r']"
                       @shortkey.native="clearFilters"/>
                <span class="shortcut">Ctrl + Shift + R</span>
              </div>
              <div class="flex flex-center column m-r-xs">
                <u-btn icon="file-import" icon-type="fa" @click="importar" class="limpar" label="Importar tabela de preços" v-shortkey="['shift', 'I']"
                       @shortkey.native="importar"/>
                <span class="shortcut">Shift + I</span>
              </div>
              <div class="flex flex-center column" v-if="cracl(null, ['sl/leilao/create'])">
                <u-btn @click="novo" class="cadastrar" label="Cadastrar" v-shortkey="['shift', 'n']"
                       @shortkey.native="novo"/>
                <span class="shortcut">Shift + N</span>
              </div>
            </e-col>
          </e-row>
        </form>
      </search-filter>
      <div class="erp-list">
        <div class="erp-list-top-btns">
          <div class="actives-btns">
<!--            <u-btn @click="changeListType('ativos')" label="Ativos" class="active-table-btn" :class="{active: listType === 'ativos'}" no-caps />
            <u-btn @click="changeListType('finalizados')" label="Finalizados" class="text-grey active-table-btn" :class="{active: listType === 'finalizados'}" no-caps />-->
          </div>
          <div class="end">
<!--            <u-btn @click="imprimir" title="Imprimir" no-caps size="sm" flat icon="print" icon-type="fa" color="grey-4" class="text-grey-8" style="padding: 1px 8px"/>
            <u-btn @click="load('excel')" title="Exportar para Excel" no-caps size="sm" flat icon="file-excel" icon-type="fa" color="grey-4" class="text-grey-8 m-r-sm" style="padding: 1px 6px"/>-->
            <u-btn @click="listOptions" label="Configurar lista" no-caps size="sm" color="grey-4" class="text-grey-8"/>
          </div>
        </div>
        <u-table
            ref="table"
            color="primary"
            :data="table.serverData"
            :columns="table.columns"
            :visible-columns="visibledColumns"
            :filter="table.busca"
            row-key="id"
            :pagination.sync="table.serverPagination"
            @request="request"
            selection="multiple"
            :selected.sync="table.selected"
            :loading="table.loading"
            class="erp-table"
            :rows-per-page-options="[20, 50, 100]"
        >
          <!--<template slot="top-right" slot-scope="props">
          </template>-->
          <u-tr slot="header" slot-scope="props" @dblclick.native="abrir(props.row.id)">
            <u-th auto-width>
              <u-checkbox
                  v-model="props.selected"
                  :indeterminate="props.partialSelected"
                  size="sm"
              />
            </u-th>
            <u-th v-for="col in props.cols" :key="col.name" :props="props">
              {{ col.label }}
            </u-th>
          </u-tr>

          <u-tr v-on:dblclick.native="abrir(props.row.id)" class="cursor-pointer" slot="body" slot-scope="props"
                :props="props" :class="{'u-table-item-destac': props.row.isToday}">
            <u-td auto-width>
              <u-checkbox color="primary" v-model="props.selected" size="xs"/>
            </u-td>
            <u-td style="max-width: 40px; min-width: 40px; width: 40px" key="id" :props="props">
              {{ props.row.id }}
            </u-td>
            <u-td key="nome" :props="props">
              {{ props.row.nome }}
            </u-td>
            <u-td key="uf" :props="props">
              {{ props.row.ufOld }}
            </u-td>
            <u-td key="cidade" :props="props">
              {{ props.row.cidadeOld }}
            </u-td>
            <u-td class="text-center" key="options" :props="props">
              <e-btn-table-options>
                <menu-options>
                  <default-list-menu @abrir="abrir(props.row.id)" @edit="edit(props.row.id)"
                                     @excluir="excluir(props.row.id)" :leilao="props.row"/>
                </menu-options>
              </e-btn-table-options>
            </u-td>
          </u-tr>
        </u-table>
      </div>
    </div>
  </layout>
</template>

<style lang="stylus" src="../assets/default.styl"></style>
